var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "layout",
    [
      _c("template", { slot: "content" }, [
        _c(
          "div",
          {
            staticClass: "section notice",
            attrs: { slot: "content" },
            slot: "content",
          },
          [
            _c(
              "GlobalDialog",
              {
                attrs: {
                  dialogVisible: _vm.isStartExam,
                  disabled: !!_vm.countdownBegin,
                  title: "试卷发放",
                  show_close: true,
                  isShowFooter: false,
                  width: "560px",
                },
                on: {
                  "dialog-cancel": _vm.startExam,
                  close: _vm.closeStartExam,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "start-exam-container" },
                  [
                    _c("div", { staticClass: "distribution" }, [
                      _c("i", {
                        staticClass:
                          "iconfont iconICAtubiao_huodongdaojishi-copy",
                      }),
                      _c("div", { staticClass: "right shunt" }, [
                        _c("span", { staticClass: "title" }, [
                          _c("span", { staticStyle: { "font-size": "24px" } }, [
                            _vm._v("发卷中"),
                          ]),
                          _vm._v("，请耐心等待"),
                        ]),
                        _c("span", { staticClass: "time" }, [
                          _vm._v("倒计时"),
                          _c("span", { staticClass: "time-detail" }, [
                            _vm._v(
                              _vm._s(
                                `00:00:${String(_vm.countdownBegin).padStart(
                                  2,
                                  "0"
                                )}`
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.countdownBegin <= 0,
                            expression: "countdownBegin <= 0",
                          },
                        ],
                        staticStyle: { width: "200px" },
                        attrs: { type: "danger" },
                        on: { click: _vm.startExam },
                      },
                      [_vm._v("开始作答")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "选择试卷",
                  width: "600px",
                  modal: false,
                  visible: _vm.showAB,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.showAB = $event
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "choseAB" },
                  [
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: "提示",
                          modal: false,
                          visible: _vm.chooseAB,
                          width: "430px",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.chooseAB = $event
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "dialog-content" }, [
                          _c("i", { staticClass: "iconfont icontishi" }),
                          _c("div", { staticClass: "tip-container" }, [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("您确认选择此试卷吗？"),
                            ]),
                            _c("span", [
                              _vm._v("提交后，"),
                              _c(
                                "span",
                                { staticStyle: { color: "#cb2a1d" } },
                                [_vm._v("不能")]
                              ),
                              _vm._v(" 再作答其他试卷！"),
                            ]),
                          ]),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "known-button",
                                attrs: {
                                  type: "danger",
                                  disabled: _vm.thinkTime !== 0,
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmChoose()
                                  },
                                },
                              },
                              [
                                _vm._v("确认选择 "),
                                _vm.thinkTime !== 0
                                  ? _c("span", [
                                      _vm._v(
                                        "( " + _vm._s(_vm.thinkTime) + "s )"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "known-button",
                                attrs: { type: "danger", plain: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.chooseAB = false
                                  },
                                },
                              },
                              [_vm._v("我再想想")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "content-title" }, [
                      _vm._v("试卷列表"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "papers" },
                      _vm._l(_vm.papers, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "paper",
                            on: {
                              click: function ($event) {
                                return _vm.choosePaper(item.key)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "content" }, [
                              _vm._v(_vm._s(_vm.mapIndex[index]) + "卷"),
                            ]),
                            _c("span", { staticClass: "content" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c("div", {
                      staticClass: "tips",
                      domProps: { innerHTML: _vm._s(_vm.groupConfirmTip) },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: `${_vm.$store.state.examInfo.coverInfo.shortName}${_vm.$store.state.examInfo.scenario}纪律`,
                  width: "700px",
                  modal: false,
                  center: true,
                  visible: _vm.showExamRule,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.showExamRule = $event
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "rule-container" },
                  [
                    _c("span", { staticClass: "rule-text" }, [
                      _vm._v(
                        _vm._s(_vm.$store.state.examInfo.coverInfo.shortName) +
                          _vm._s(_vm.$store.state.examInfo.scenario) +
                          "纪律如下，请逐项阅读并同意后，进入考试。 "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "rule-item" },
                      [
                        _c("span", { staticClass: "rule-text" }, [
                          _vm._v(
                            "1、开考后，禁止使用除答题所用电脑、监控手机外，任何辅助考试的电子设备或与考试内容相关的书籍资料。一经发现，终止考试，取消考试资格，成绩按0分计；"
                          ),
                        ]),
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "rule-button",
                            attrs: { size: "small" },
                            model: {
                              value: _vm.radio1,
                              callback: function ($$v) {
                                _vm.radio1 = $$v
                              },
                              expression: "radio1",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("同意"),
                            ]),
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("不同意"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "rule-item" },
                      [
                        _c("span", { staticClass: "rule-text" }, [
                          _vm._v(
                            "2、未按要求开启考试所用电脑摄像头监控、手机视频监控、电脑桌面监控，及监控手机未按要求摆放，考官两次提醒仍未改正后，即终止考试，成绩按0分计；"
                          ),
                        ]),
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "rule-button",
                            attrs: { size: "small" },
                            model: {
                              value: _vm.radio2,
                              callback: function ($$v) {
                                _vm.radio2 = $$v
                              },
                              expression: "radio2",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("同意"),
                            ]),
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("不同意"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "rule-item" },
                      [
                        _c("span", { staticClass: "rule-text" }, [
                          _vm._v(
                            "3、考试中，考官会随机抽查考生及考位周围环境，发出要求视频抽查周围环境指令时，需要立刻按要求用监控手机配合考官进行视频检查，故意拖延时间、让考官无法看清周围环境的，即终止考试，成绩按0分计；"
                          ),
                        ]),
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "rule-button",
                            attrs: { size: "small" },
                            model: {
                              value: _vm.radio3,
                              callback: function ($$v) {
                                _vm.radio3 = $$v
                              },
                              expression: "radio3",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("同意"),
                            ]),
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("不同意"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "rule-item" },
                      [
                        _c("span", { staticClass: "rule-text" }, [
                          _vm._v(
                            "4、存在以下作弊行为，即终止考试，成绩按0分计。作弊行为包括不限于：由他人替考；抄袭试题相关答案；答案与他人雷同；接打电话；以不正当手段试图获得试题答案；复制、记录试卷内容等。"
                          ),
                        ]),
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "rule-button",
                            attrs: { size: "small" },
                            model: {
                              value: _vm.radio4,
                              callback: function ($$v) {
                                _vm.radio4 = $$v
                              },
                              expression: "radio4",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("同意"),
                            ]),
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("不同意"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "rule-item" },
                      [
                        _c("span", { staticClass: "rule-text" }, [
                          _vm._v(
                            "5、存在以下行为，将先红牌警告，视情节轻重，视为“考试作弊”，终止考试，成绩按0分计。作弊行为包括不限于：离开座位、戴耳机、故意遮挡、目光频繁离开电脑屏幕、外接电脑屏幕等设备，等等。 "
                          ),
                        ]),
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "rule-button",
                            attrs: { size: "small" },
                            model: {
                              value: _vm.radio5,
                              callback: function ($$v) {
                                _vm.radio5 = $$v
                              },
                              expression: "radio5",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("同意"),
                            ]),
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("不同意"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "rule-item" },
                      [
                        _c("span", { staticClass: "rule-text" }, [
                          _vm._v(
                            "6、考生在考试过程中如有作弊行为，会在考试报告里标记“考试违纪”或“考试作弊”字样，并长期保存在国考云考生诚信档案系统中。 "
                          ),
                        ]),
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "rule-button",
                            attrs: { size: "small" },
                            model: {
                              value: _vm.radio6,
                              callback: function ($$v) {
                                _vm.radio6 = $$v
                              },
                              expression: "radio6",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("同意"),
                            ]),
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("不同意"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          "align-self": "center",
                          "margin-top": "20px",
                        },
                        attrs: { type: "primary", disabled: !_vm.checkRule() },
                        on: { click: _vm.startOrShunt },
                      },
                      [_vm._v(" 开始作答 ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "notice-content" },
              [
                _c("div", {
                  staticClass: "notice-note",
                  domProps: { innerHTML: _vm._s(_vm.strReplace(_vm.notice)) },
                }),
                _c(
                  "el-checkbox",
                  {
                    staticClass: "myRedCheckBox",
                    model: {
                      value: _vm.checked,
                      callback: function ($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked",
                    },
                  },
                  [
                    _vm._v(
                      "我同意接受以上内容。我承诺个人独立完成作答，答题中不会获取网络、书籍、他人的帮助。"
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "operate" },
              [
                _c("span", { staticClass: "prev", on: { click: _vm.prev } }, [
                  _vm._v("上一步"),
                ]),
                !_vm.$store.state.isPractice
                  ? _c(
                      "el-button",
                      {
                        staticClass: "start-exam",
                        style:
                          !!_vm.countDown || !_vm.checked
                            ? "background-color:#ccc"
                            : "",
                        attrs: { disabled: !!_vm.countDown || !_vm.checked },
                        on: { click: _vm.start },
                      },
                      [
                        _vm._v(
                          " 开始作答 " +
                            _vm._s(_vm.countDown ? `(${_vm.countDown})` : "") +
                            " "
                        ),
                      ]
                    )
                  : _c(
                      "el-button",
                      {
                        staticClass: "start-exam",
                        style: !_vm.checked ? "background-color:#ccc" : "",
                        attrs: { disabled: !_vm.checked },
                        on: { click: _vm.startPratice },
                      },
                      [_vm._v(" 开始练习 ")]
                    ),
                _c("span", { staticClass: "next" }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }