<!--
 * @Author: Libra
 * @Date: 2022-04-01 10:09:11
 * @LastEditTime: 2023-02-17 17:03:13
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/views/confirm/notice/index.vue
-->
<template>
  <layout>
    <template slot="content">
      <div slot="content" class="section notice">
        <GlobalDialog
          :dialogVisible="isStartExam"
          @dialog-cancel="startExam"
          :disabled="!!countdownBegin"
          title="试卷发放"
          :show_close="true"
          :isShowFooter="false"
          width="560px"
          @close="closeStartExam"
        >
          <div class="start-exam-container">
            <div class="distribution">
              <i class="iconfont iconICAtubiao_huodongdaojishi-copy"></i>
              <div class="right shunt">
                <span
class="title"
                  ><span style="font-size: 24px;">发卷中</span
                  >，请耐心等待</span
                >
                <span
class="time"
                  >倒计时<span class="time-detail">{{
                    `00:00:${String(countdownBegin).padStart(2, '0')}`
                  }}</span></span
                >
              </div>
            </div>
            <el-button
              style="width: 200px;"
              v-show="countdownBegin <= 0"
              type="danger"
              @click="startExam"
              >开始作答</el-button
            >
          </div>
        </GlobalDialog>
        <el-dialog
          title="选择试卷"
          width="600px"
          :modal="false"
          :visible.sync="showAB"
        >
          <div class="choseAB">
            <el-dialog
              title="提示"
              :modal="false"
              :visible.sync="chooseAB"
              width="430px"
            >
              <div class="dialog-content">
                <i class="iconfont icontishi"></i>
                <div class="tip-container">
                  <span class="label">您确认选择此试卷吗？</span>
                  <span
                    >提交后，<span style="color: #cb2a1d;">不能</span>
                    再作答其他试卷！</span
                  >
                </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button
                  class="known-button"
                  type="danger"
                  :disabled="thinkTime !== 0"
                  plain
                  @click="confirmChoose()"
                  >确认选择
                  <span
v-if="thinkTime !== 0"
                    >( {{ thinkTime }}s )</span
                  ></el-button
                >
                <el-button
                  class="known-button"
                  type="danger"
                  plain
                  @click="chooseAB = false"
                  >我再想想</el-button
                >
              </span>
            </el-dialog>
            <div class="content-title">试卷列表</div>
            <div class="papers">
              <div
                class="paper"
                @click="choosePaper(item.key)"
                v-for="(item, index) in papers"
                :key="index"
              >
                <span class="content">{{ mapIndex[index] }}卷</span>
                <span class="content">{{ item.name }}</span>
              </div>
            </div>
            <div class="tips" v-html="groupConfirmTip"></div>
          </div>
        </el-dialog>
        <el-dialog
          :title="`${$store.state.examInfo.coverInfo.shortName}${$store.state.examInfo.scenario}纪律`"
          width="700px"
          :modal="false"
          :center="true"
          :visible.sync="showExamRule"
        >
          <div class="rule-container">
            <span
class="rule-text"
              >{{ $store.state.examInfo.coverInfo.shortName
              }}{{
                $store.state.examInfo.scenario
              }}纪律如下，请逐项阅读并同意后，进入考试。
            </span>
            <div class="rule-item">
              <span
class="rule-text"
                >1、开考后，禁止使用除答题所用电脑、监控手机外，任何辅助考试的电子设备或与考试内容相关的书籍资料。一经发现，终止考试，取消考试资格，成绩按0分计；</span
              >
              <el-radio-group class="rule-button" v-model="radio1" size="small">
                <el-radio-button label="0">同意</el-radio-button>
                <el-radio-button label="1">不同意</el-radio-button>
              </el-radio-group>
            </div>
            <div class="rule-item">
              <span
class="rule-text"
                >2、未按要求开启考试所用电脑摄像头监控、手机视频监控、电脑桌面监控，及监控手机未按要求摆放，考官两次提醒仍未改正后，即终止考试，成绩按0分计；</span
              >
              <el-radio-group class="rule-button" v-model="radio2" size="small">
                <el-radio-button label="0">同意</el-radio-button>
                <el-radio-button label="1">不同意</el-radio-button>
              </el-radio-group>
            </div>
            <div class="rule-item">
              <span
class="rule-text"
                >3、考试中，考官会随机抽查考生及考位周围环境，发出要求视频抽查周围环境指令时，需要立刻按要求用监控手机配合考官进行视频检查，故意拖延时间、让考官无法看清周围环境的，即终止考试，成绩按0分计；</span
              >
              <el-radio-group class="rule-button" v-model="radio3" size="small">
                <el-radio-button label="0">同意</el-radio-button>
                <el-radio-button label="1">不同意</el-radio-button>
              </el-radio-group>
            </div>
            <div class="rule-item">
              <span
class="rule-text"
                >4、存在以下作弊行为，即终止考试，成绩按0分计。作弊行为包括不限于：由他人替考；抄袭试题相关答案；答案与他人雷同；接打电话；以不正当手段试图获得试题答案；复制、记录试卷内容等。</span
              >
              <el-radio-group class="rule-button" v-model="radio4" size="small">
                <el-radio-button label="0">同意</el-radio-button>
                <el-radio-button label="1">不同意</el-radio-button>
              </el-radio-group>
            </div>
            <div class="rule-item">
              <span
class="rule-text"
                >5、存在以下行为，将先红牌警告，视情节轻重，视为“考试作弊”，终止考试，成绩按0分计。作弊行为包括不限于：离开座位、戴耳机、故意遮挡、目光频繁离开电脑屏幕、外接电脑屏幕等设备，等等。
              </span>
              <el-radio-group class="rule-button" v-model="radio5" size="small">
                <el-radio-button label="0">同意</el-radio-button>
                <el-radio-button label="1">不同意</el-radio-button>
              </el-radio-group>
            </div>
            <div class="rule-item">
              <span
class="rule-text"
                >6、考生在考试过程中如有作弊行为，会在考试报告里标记“考试违纪”或“考试作弊”字样，并长期保存在国考云考生诚信档案系统中。
              </span>
              <el-radio-group class="rule-button" v-model="radio6" size="small">
                <el-radio-button label="0">同意</el-radio-button>
                <el-radio-button label="1">不同意</el-radio-button>
              </el-radio-group>
            </div>
            <el-button
              type="primary"
              style="align-self: center;margin-top: 20px;"
              :disabled="!checkRule()"
              @click="startOrShunt"
            >
              开始作答
            </el-button>
          </div>
        </el-dialog>
        <div class="notice-content">
          <div class="notice-note" v-html="strReplace(notice)"></div>
          <el-checkbox
class="myRedCheckBox"
v-model="checked"
            >我同意接受以上内容。我承诺个人独立完成作答，答题中不会获取网络、书籍、他人的帮助。</el-checkbox
          >
        </div>
        <div class="operate">
          <span class="prev" @click="prev">上一步</span>
          <el-button
            v-if="!$store.state.isPractice"
            class="start-exam"
            :disabled="!!countDown || !checked"
            @click="start"
            :style="!!countDown || !checked ? 'background-color:#ccc' : ''"
          >
            开始作答 {{ countDown ? `(${countDown})` : '' }}
          </el-button>
          <el-button
            :disabled="!checked"
            v-else
            class="start-exam"
            @click="startPratice"
            :style="!checked ? 'background-color:#ccc' : ''"
          >
            开始练习
          </el-button>
          <span class="next"></span>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from '@/views/confirm/layout'
import GlobalDialog from '@/components/GlobalDialog'
import { mapMutations, mapActions } from 'vuex'
import Api from '@/api/api'

export default {
  name: 'ConfirmNotice',
  components: {
    Layout,
    GlobalDialog
  },
  data() {
    return {
      checked: true,
      countDown: 10,
      secondToStart: null,
      timer: null,
      countDownTimer: null,
      notice: '',
      thinkTime: 4,
      isStartExam: false,
      countdownBegin: this.$store.state.userInfo.countdownBegin,
      groupConfirmTip: this.$store.state.jobInfo.groupConfirmTip,
      mobileMonitor: 0,
      showAB: false,
      showExamRule: false,
      chooseAB: false,
      selectedPaper: null,
      papers: [],
      cameraMonitor: this.$store.state.examInfo.cameraMonitorType === 0,
      isFaceRecognizeNeed: this.$store.state.examInfo.isFaceRecognizeNeed,
      radio1: '',
      radio2: '',
      radio3: '',
      radio4: '',
      radio5: '',
      radio6: '',
      mapIndex: {
        0: 'A',
        1: 'B',
        2: 'C',
        3: 'D',
        4: 'E',
        5: 'F',
        6: 'G',
        7: 'H',
        8: 'I',
        9: 'J',
        10: 'K',
        11: 'L',
        12: 'M',
        13: 'N',
        14: 'O'
      }
    }
  },
  created() {
    const { mobileMonitorType, mobileVideoType } = this.$store.state.examInfo
    this.mobileMonitor = mobileMonitorType === 0 && mobileVideoType === 0
  },
  watch: {
    '$store.state.isPractice': {
      handler(val) {
        if (!val && !this.timer) {
          this.countDown = 10
          this.timer = setInterval(() => {
            if (this.countDown > 0) {
              this.countDown--
            } else {
              clearInterval(this.timer)
            }
          }, 1000)
        }
      }
    }
  },
  async mounted() {
    this.notice = this.$store.state.examInfo.confirmNote || ''
    const { isPractice } = this.$store.state
    if (isPractice === null) return
    if (!isPractice && !this.timer) {
      this.timer = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    }
  },
  methods: {
    ...mapActions([`updateUserInfo`]),
    ...mapMutations([`setUserInfo`]),
    // 上一步
    prev() {
      const { mobileMonitorType, mobileVideoType } = this.$store.state.examInfo
      const { idCardType } = this.$store.state.userInfo
      if (mobileMonitorType === 0 && mobileVideoType === 0) {
        if (this.cameraMonitor) {
          this.$router.push('/basic')
          return
        }
        if (this.isFaceRecognizeNeed && idCardType === 1) {
          this.$router.push('/face')
        } else {
          this.$router.push('/camera')
        }
      } else {
        this.$router.push('/mobile')
      }
    },
    // 下一步
    async startExam() {
      let res = null
      try {
        res = await Api.examStart()
        if (res.code === 0) {
          const res = await Api.memberInfo()
          if (res.code === 0) {
            const data = res.data
            this.setUserInfo(data)
            this.updateUserInfo(data)
          }
          this.$router.push('/exam')
        } else if (res.code === 30010) {
          await this.getABPaper()
          this.isStartExam = false
        } else {
          this.isStartExam = false
        }
      } catch (err) {
        this.isStartExam = false
        console.log(err)
      }
    },
    async startPratice() {
      try {
        const res = await Api.partPracticeStart()
        if (res.code === 0) {
          this.$router.push('/exam?isPractice=true')
        }
      } catch (err) {
        console.log(err)
      }
    },
    CountDown() {
      if (this.countDownTimer !== null) return
      this.countDownTimer = setInterval(() => {
        if (this.countdownBegin > 0) {
          this.countdownBegin--
        } else {
          clearInterval(this.countDownTimer)
          this.countDownTimer = null
        }
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.countDownTimer)
        this.countDownTimer = null
      })
    },
    closeStartExam() {
      this.isStartExam = false
      this.countdownBegin = this.$store.state.userInfo.countdownBegin
    },
    async start() {
      // 是否开启二机位
      const { mobileVideoType, category } = this.$store.state.examInfo
      const isOpen = mobileVideoType !== 0 && category !== 3
      if (!this.checked) {
        this.$message.info('请先同意接受以上内容')
        return
      }
      isOpen ? this.openRule() : await this.startOrShunt()
    },
    // 开启二机位，强制规则
    openRule() {
      this.showExamRule = true
    },
    // 全部点同意才可以开始作答
    checkRule() {
      return (
        this.radio1 === '0' &&
        this.radio2 === '0' &&
        this.radio3 === '0' &&
        this.radio4 === '0' &&
        this.radio5 === '0' &&
        this.radio6 === '0'
      )
    },
    // flag 随来随考 + AB卷 不用重新分流
    async startOrShunt(flag = false) {
      const startedAt = this.$store.state.userInfo.startedAt
      if (startedAt || flag) {
        await this.startExam()
      } else {
        await this.shuntRule()
      }
    },
    async getABPaper() {
      this.showAB = true
      const res = await Api.getABPaper()
      if (res.code === 0) {
        this.papers = res.data
      }
    },
    // 分流规则
    // 开考发卷需要判断如果当前点击开始作答时间距离开考时间已大于分流时间就不分流直接进入
    async shuntRule() {
      const res = await Api.getTime()
      if (res.code === 0) {
        // 获取当前时间
        const { timestamp, examStartAt } = res.data
        const curTime = Number(timestamp)
        if (
          curTime * 1000 - new Date(examStartAt).getTime() >
          this.countdownBegin * 1000
        ) {
          await this.startExam()
        } else {
          this.isStartExam = true
          this.CountDown(this.countdownBegin)
        }
      }
    },
    // 字符串替换
    strReplace(str) {
      if (!str) return
      const s = str.replace(/&lt;/g, '<')
      return s.replace(/&gt;/g, '>')
    },
    async confirmChoose() {
      const res = await Api.selectABPaper(this.selectedPaper)
      if (res.code === 0) {
        await this.startOrShunt(true)
      }
    },
    // 选择试卷
    choosePaper(paper) {
      this.chooseAB = true
      this.selectedPaper = paper
      this.chooseABCountDown()
    },
    // 选择试卷倒计时
    chooseABCountDown() {
      const timer = setInterval(() => {
        if (this.thinkTime > 0) {
          this.thinkTime--
        } else {
          clearInterval(timer)
        }
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/variables.scss';
@import './index.scss';
</style>
